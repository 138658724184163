import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';
import {Loader, LoaderOptions} from 'google-maps';

class ContactMap extends BaseComponent {

    constructor(el, componentName) {
        super(el, componentName);

        this.init();
    }

    init = () => {
        const loader = new Loader('AIzaSyCpaFo2YEw0jGiNXNx47kgf5rjmjT4UE1c');

        loader.load().then(function (google) {
            const companyLocation = {lat: 50.1693229, lng: 14.0577447};

            const map = new google.maps.Map(document.getElementById("map"), {
                center: companyLocation,
                mapId: 'fc5183eea6fe4b99',
                zoom: 10
            });

            const marker = new google.maps.Marker({
                position: companyLocation,
                map: map,
            });
        });
    }

    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el,
            onInViewport: (target) => {
                gsap.from(target, {
                    duration: 0.6,
                    opacity: 0,
                    x: 60,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('ContactMap', ContactMap);

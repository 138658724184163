import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class ProductTechnicalInformation extends BaseComponent {
    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el,
            onInViewport: (target) => {
                tl.from(target.querySelector('.ProductTechnicalInformation__heading'), {
                    duration: 0.6,
                    opacity: 0,
                    x: 30,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                // starting animation immediately intentionally
                gsap.from(target.querySelector('.ProductTechnicalInformation__table-wrapper'), {
                    duration: 0.6,
                    opacity: 0,
                    x: 30,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('ProductTechnicalInformation', ProductTechnicalInformation);

import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class ProductCards extends BaseComponent {
    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el.querySelectorAll('.ProductCards__card'),
            onInViewport: (target) => {
                tl.from(target, {
                    duration: 0.6,
                    opacity: 0,
                    x: 30,
                    stagger: 0.13,
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('ProductCards', ProductCards);

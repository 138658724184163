import LazyLoad from 'vanilla-lazyload';
import Events from '../utilities/customEvents';

const LAZY_CLASS = '.lazy';

const callback_loaded = (el) => {
  const mediaParentEl = el.closest('.media');
  if (mediaParentEl) {
    mediaParentEl.classList.add('loaded');
  }
};

const lazyLoadInstance = new LazyLoad({
  elements_selector: LAZY_CLASS,
  thresholds: '50% 100%',
  unobserve_entered: true,
  cancel_on_exit: false,
  callback_loaded,
});

// after updating elements' innerHTML, this needs to be fired to observe the new DOM elements.
document.body.addEventListener(Events.AJAX_UPDATE, (e) => {
  if (lazyLoadInstance) {
    lazyLoadInstance.update();
  }
});

document.body.addEventListener(Events.TRIGGER_LOAD_LAZY, (e) => {
  const { el } = e.detail;
  if (!el) return;

  let elsToLoad = [];

  const els = [].concat(el);

  els.forEach(el => {
    if (el.classList.contains(LAZY_CLASS)) {
      elsToLoad = elsToLoad.concat([el]);
    } else {
      elsToLoad = elsToLoad.concat([...el.querySelectorAll(LAZY_CLASS)]);
    }
  });

  elsToLoad.forEach(imgEl => {
    LazyLoad.load(imgEl, {
      unobserve_entered: true,
      cancel_on_exit: false,
      callback_loaded
    });
  });
});

import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class ContactOffice extends BaseComponent {
    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el,
            onInViewport: (target) => {
                const contentTargets = target.querySelector('.ContactOffice__content').children;
                gsap.from(contentTargets, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const imageTarget = target.querySelector('.ContactOffice__image');
                gsap.from(imageTarget, {
                    duration: 0.6,
                    opacity: 0,
                    x: 60,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                // background fade in
                gsap.from(this.el, {
                    duration: 1.2,
                    opacity: 0,
                    background: 'linear-gradient(90deg, #FFFFFF 50%, #FFFFFF 50%)',
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('ContactOffice', ContactOffice);

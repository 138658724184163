import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const allPageHashLinks = document.querySelectorAll('a[href^="#"]');
const handleHashNavigation = (e) => {
    e.preventDefault();

    gsap.to(
        window,
        {
            duration: 1,
            ease: 'power1.out',
            scrollTo: {
                y: e.currentTarget.getAttribute('href'),
                autoKill: false
            }
        })
}

allPageHashLinks.forEach((a) => a.addEventListener('click', handleHashNavigation));

import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import Form, {MESSAGE_TYPES} from '../components/Form.js';
import {api} from '../api.js';

const MAKE_ENDPOINT = 'https://hook.eu1.make.com/m1xyuwnw3i1n7jn66yuq3qnncaskaqif';

class CommonLightContactForm extends Form {
    async onSubmit(formData) {

        // compose e-mail message
        const emailAddressOrPhone = formData.get('emailAddressOrPhone');
        const messageSubject = `🚨 Nový kontakt z webových stránek www.fkservis.cz (${emailAddressOrPhone})`;
        const messageBody = `Dobrý den, <br/> <br/> někdo má zájem o naše služby. Prosím, kontaktujte co nejdříve zákazníka na telefonním čísle nebo e-mailové adrese: <b>${emailAddressOrPhone}</b>. <br/><br/> Děkuji!`;

        try {
            const {data} = await api(MAKE_ENDPOINT, {
                method: 'POST',
                body: JSON.stringify({messageSubject, messageBody})
            })

            console.log('Data received: ', data);
            this.refs.form.reset();
            this.displayMessage({message: `Děkujeme, vaše kontaktní údaje k nám právě dorazily. V nejbližším možném termínu vás budeme kontaktovat.`});
        } catch (errResponse) {
            console.error(errResponse);
            this.displayMessage({type: MESSAGE_TYPES.ERROR, message: 'Při odesílání formuláře se stala chyba'});
            // handle error
            // const {status, data, message} = errResponse;
        }
    }
}

RuntimeComponentFactory.add('CommonLightContactForm', CommonLightContactForm);

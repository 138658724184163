import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class AboutTimeline extends BaseComponent {
  get revealAnimations() {
    const tl = gsap.timeline();
    return [{
      targets: this.el.querySelectorAll('.AboutTimeline__event'),
      onInViewport: (target) => {
        const targetTl = gsap.timeline();
        const elements = {
          circle: target.querySelector('.AboutTimeline__circle'),
          content: target.querySelector('.AboutTimeline__event-content'),
          figure: target.querySelector('figure'),
        }

        targetTl.from(elements.circle, {
          duration: 0.3,
          scale: 0,
          borderWidth: '28px',
          clearProps: 'all',
          ease: 'power2.easeOut',
        });

        targetTl.from(target, {
          duration: 0.2,
          clearProps: 'all',
          "--horizontal-line-scale": 0,
        })

        targetTl.from([elements.content, elements.figure], {
          duration: 0.5,
          opacity: 0,
          y: 60,
          clearProps: 'all',
          ease: 'power2.easeOut',
        }, '>-0.1')
        tl.add(targetTl, tl.isActive() ? '<0.13' : '+=0')// stagger 0.13s with independent reveal animations
      }
    }]
  }
}

RuntimeComponentFactory.add('AboutTimeline', AboutTimeline);

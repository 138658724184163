import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class ReferencesList extends BaseComponent {
    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el.querySelectorAll('.Reference'),
            onInViewport: (target) => {
                // more complex version
                // const contentTargets = target.querySelector('.Reference__content-wrapper').children;
                // gsap.from(contentTargets, {
                //     duration: 0.6,
                //     opacity: 0,
                //     y: 30,
                //     stagger: 0.13,
                //     transition: 'none',
                //     clearProps: 'all',
                //     ease: 'power2.easeOut'
                // });
                //
                // const imageTarget = target.querySelector('.Reference__image');
                // gsap.from(imageTarget, {
                //     duration: 0.6,
                //     opacity: 0,
                //     y: 30,
                //     transition: 'none',
                //     clearProps: 'all',
                //     ease: 'power2.easeOut'
                // });

                // simpler version
                tl.from(target, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    ease: 'power2.easeOut'
                }, tl.isActive() ? '<0.13' : '+=0') // stagger 0.13s with independent reveal animations);
            }
        }];
    }
}

RuntimeComponentFactory.add('ReferencesList', ReferencesList);

import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class SolutionHero extends BaseComponent {
    get revealAnimations() {
        return [{
            targets: this.el,
            onInViewport: (target) => {
                const contentTargets = target.querySelector('.SolutionHero__content').children;
                gsap.from(contentTargets, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const imageTarget = target.querySelector('.SolutionHero__image');
                gsap.from(imageTarget, {
                    duration: 0.6,
                    opacity: 0,
                    x: 60,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const dividerTarget = target.querySelector('.SolutionHero__divider');
                gsap.from(dividerTarget, {
                    duration: 1.2,
                    opacity: 0,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('SolutionHero', SolutionHero);

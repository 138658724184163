import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class CareerOpenPositionsList extends BaseComponent {
    get revealAnimations() {
        return [{
            targets: this.el.querySelectorAll('.OpenPosition'),
            onInViewport: (target) => {
                const contentTargets = target.querySelector('.OpenPosition__content').children;
                gsap.from(contentTargets, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const imageTarget = target.querySelector('.OpenPosition__image');
                gsap.from(imageTarget, {
                    duration: 0.6,
                    opacity: 0,
                    y: 60,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const bgTarget = target.querySelector('.OpenPosition__bg');
                gsap.from(bgTarget, {
                    duration: 1.2,
                    backgroundColor: '#FFFFFF',
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('CareerOpenPositionsList', CareerOpenPositionsList);

import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class CommonBreadcrumbs extends BaseComponent {
    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el.querySelectorAll('li'),
            onInViewport: (target) => {
                tl.from(target, {
                    duration: 0.6,
                    opacity: 0,
                    x: 30,
                    stagger: 0.13,
                    ease: 'power2.easeOut'
                }, tl.isActive() ? '<0.13' : '+=0') // stagger 0.13s with independent reveal animations);
            }
        }];
    }
}

RuntimeComponentFactory.add('CommonBreadcrumbs', CommonBreadcrumbs);

import { BaseComponent } from './_BaseComponent.js';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper/core';
import Events, { dispatch } from '../utilities/customEvents.js';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

export default class Slider extends BaseComponent {
  requiredRefs = ['container'];
  slider = null;

  constructor(el, componentName) {
    super(el, componentName);

    const hasNavigation = this.refs.buttonNext || this.refs.buttonPrev;
    const hasPagination = this.refs.pagination;
    const isAutoplay = !!this.el.dataset.autoplay;

    const defaultOptions = {
      spaceBetween: 10,
      speed: 600,
      loop: false,
      watchOverflow: true,
      autoplay: isAutoplay ? {
        delay: parseInt(this.el.dataset.autoplay, 10) || 3000
      } : false,
      navigation: hasNavigation ? {
        nextEl: this.refs.buttonNext,
        prevEl: this.refs.buttonPrev,
      } : false,
      pagination: hasPagination ? {
        el: this.refs.pagination,
        clickable: true,
        type: 'bullets',
      } : false,
      on: {
        afterInit: this.handleChange,
        slideChange: this.handleChange
      }
    };

    const {swiperOptions } = el.dataset;
    const passedOptions = ((swiperOptions && JSON.parse(swiperOptions)) || {});

    this.options = {
      ...defaultOptions,
      ...passedOptions,
      on: {
        ...(passedOptions.on || {}),
        ...defaultOptions.on,
      }
    }

    this.init();
  }

  getCurrentSlide = (slider) => {
    const swiperInstace = (slider || this.slider);
    return swiperInstace && swiperInstace.slides[swiperInstace.activeIndex];
  }

  init = () => {
    this.slider = new Swiper(this.refs.container, this.options);

    this.slider.getCurrentSlide = this.getCurrentSlide;

    this.el.slider = this.slider; // expose slider to other components
  };

  handleChange = (slider) => {
    const currentSlide = this.getCurrentSlide(slider);
    const siblings = [currentSlide.nextElementSibling, currentSlide.previousElementSibling].filter(el => !!el);
    dispatch(document.body, Events.TRIGGER_LOAD_LAZY, { el: siblings });
  };
}

RuntimeComponentFactory.add('Slider', Slider);

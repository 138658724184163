import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import { BaseComponent } from '../components/_BaseComponent.js';
import Events , { dispatch } from '../utilities/customEvents.js';

class Header extends BaseComponent {
  requiredRefs = ['redboxContainer'];

  constructor(el, componentName) {
    super(el, componentName);

    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const {width} = entry.target.getBoundingClientRect(); // need to use getBoundingCR because only that includes paddings.
        // save width of red box container to dochument
        document.documentElement.style.setProperty('--redbox-header-width', `${width}px`);
      }
    });
    observer.observe(this.refs.redboxContainer);

    this.el.addEventListener('mouseleave', this.handleMouseLeave, {passive: true})
  }

  handleMouseLeave = () => {
    dispatch(document.body, Events.HEADER_MOUSELEAVE)
  }
}

RuntimeComponentFactory.add('Header', Header);


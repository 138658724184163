import gsap from 'gsap';
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

// returns the depth of the element "e" relative to element root
// for this calculation only parents with classname = waypoint are considered
function getLevelDepth(el, root, waypoint, cnt) {
    cnt = cnt || 0;
    if (el === root) return cnt;
    if (el.classList.contains(waypoint)) {
        ++cnt;
    }
    return el.parentNode && getLevelDepth(el.parentNode, root, waypoint, cnt);
}

const BACK_CLASS = 'PushMenu__back';
const EVENT_NAME = 'click';

class PushMenu extends BaseComponent {
    // if menu is open or not
    open = false;
    // level depth
    level = 0;

    constructor(el, componentName) {
        super(el, componentName);

        this.dom = {
            trigger: document.querySelector('[data-pushmenu-trigger]'),
            levelBack: [...this.el.querySelectorAll(`.${BACK_CLASS}`)],
            menuItems: [...this.el.querySelectorAll('li')],
            levels: [...this.el.querySelectorAll('.PushMenu__level')],
        };

        this.init();
    }


    init = () => {
        // save the depth of each of these level elements
        this.dom.levels.forEach((el, i) => {
            el.setAttribute('data-level', getLevelDepth(el, this.el, 'PushMenu__level'));
        });
        // initialize / bind the necessary events
        this.initEvents();
    };

    initEvents = () => {
        // open (or close) the menu
        this.dom.trigger.addEventListener(EVENT_NAME, (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (this.open) {
                this.closeMenu();
            } else {
                this.openMenu();
            }
        });

        // opening a sub level menu
        this.dom.menuItems.forEach((el, i) => {
            // check if it has a sub level
            const subLevel = el.querySelector('.PushMenu__level');
            if (subLevel) {
                el.querySelector('a').addEventListener(EVENT_NAME, (ev) => {
                    ev.preventDefault();
                    const level = el.closest('.PushMenu__level').getAttribute('data-level');
                    if (this.level <= level) {
                        ev.stopPropagation();
                        el.closest('.PushMenu__level').classList.add('PushMenu__level--overlayed');
                        this.openMenu(subLevel);
                    }
                });
            }
        });

        // by clicking on a specific element
        this.dom.levelBack.forEach((el, i) => {
            el.addEventListener(EVENT_NAME, (e) => {
                e.preventDefault();
                const {level} = el.closest('.PushMenu__level').dataset;
                if (this.level <= level) {
                    e.stopPropagation();
                    this.level = el.closest('.PushMenu__level').getAttribute('data-level') - 1;
                    this.level === 0 ? this.closeMenu() : this.closeLevel();
                }
            });
        });
    };

    openMenu = (subLevel) => {
        // increment level depth
        ++this.level;
        const currentLevelEl = (subLevel || this.dom.levels[0]);

        if (this.level === 1) {
            this.open = true;
            gsap.to(this.el, {xPercent: 0, x: 0});
            this.togglerOpen();
            console.log( this.dom.levels[0])

            const tl = gsap.timeline();
            const navItemsTarget = this.dom.levels[0].parentElement.querySelectorAll('[data-level="1"] > ul > li');
            tl.from(navItemsTarget, {
                duration: 0.6,
                opacity: 0,
                x: -120,
                stagger: 0.13,
                transition: 'none',
                clearProps: 'all',
                ease: 'power2.easeOut'
            }, tl.isActive() ? '<0.13' : '+=0') // stagger 0.13s with independent reveal animations);
        }
        currentLevelEl.classList.add('is-open');
        clearAllBodyScrollLocks();
        disableBodyScroll(currentLevelEl)
    };

    closeMenu = () => {
        this.level = 0;
        this.toggleLevels();
        this.togglerClose();
        gsap.to(this.el, {xPercent: -100});
        clearAllBodyScrollLocks();
        this.open = false;
    };

    closeLevel = () => {
        this.toggleLevels();
    };

    toggleLevels = () => {
        for (let i = 0, len = this.dom.levels.length; i < len; ++i) {
            const levelEl = this.dom.levels[i];
            if (levelEl.getAttribute('data-level') >= this.level + 1) {
                // level close
                levelEl.classList.remove('is-open');
                levelEl.classList.remove('PushMenu__level--overlayed');
                enableBodyScroll(levelEl)
            } else if (parseInt(levelEl.getAttribute('data-level')) === this.level) {
                // closing to previous level
                levelEl.classList.remove('PushMenu__level--overlayed');
                disableBodyScroll(levelEl)
            }
        }
    };


    togglerOpen = () => {
        const targets = this.dom.trigger.querySelectorAll('.bar');
        const tl = gsap.timeline();
        tl.to(targets[0], {
            y: 5,
            duration: .075
        }, 0);
        tl.to(targets[0], {
            duration: .075,
            delay: .12,
            rotate: '45deg',
            transformOrigin: 'center',
            opacity: 0
        }, 0);
        tl.to(targets[1], {
            duration: .075,
            delay: .12,
            rotate: '45deg',
            transformOrigin: 'center'
        }, 0);
        tl.to(targets[2], {
            y: -5,
            duration: .075
        }, 0);
        tl.to(targets[2], {
            duration: .075,
            delay: .12,
            rotate: '-45deg',
            transformOrigin: 'center'
        }, 0);
        tl.duration(.5);
        return tl;
    };


    togglerClose = () => {
        const targets = this.dom.trigger.querySelectorAll('.bar');
        const tl = gsap.timeline();
        tl.to(targets[0], {
            y: 0,
            delay: .12,
            duration: .075
        }, 0);
        tl.to(targets[0], {
            duration: .075,
            rotate: '0deg',
            transformOrigin: 'center',
            opacity: 1
        }, 0);
        tl.to(targets[1], {
            duration: .075,
            rotate: '0deg',
            transformOrigin: 'center'
        }, 0);
        tl.to(targets[2], {
            y: 0,
            delay: .12,
            duration: .075
        }, 0);
        tl.to(targets[2], {
            duration: .075,
            rotate: '-0deg',
            transformOrigin: 'center'
        }, 0);
        tl.duration(.5);
        return tl;
    };
}

RuntimeComponentFactory.add('PushMenu', PushMenu);

import { BaseComponent } from './_BaseComponent.js';
import gsap from 'gsap';

export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};

// this component should be always extended by a component for a specific implementation.
export default class Form extends BaseComponent {
  requiredRefs = ['form'];

  get revealAnimations() {
    const tl = gsap.timeline();
    return [{
      targets: this.el,
      onInViewport: (target) => {
        const inputsTarget = target.querySelector('.Form__inputs .row').children;
        tl.from(inputsTarget, {
          duration: 0.6,
          opacity: 0,
          y: 30,
          stagger: 0.13,
          transition: 'none',
          clearProps: 'all',
          ease: 'power2.easeOut'
        }, tl.isActive() ? '<0.13' : '+=0'); // stagger 0.13s with independent reveal animations);
      }
    }];
  }

  constructor(el, componentName) {
    super(el, componentName);
    this.init();
  }

  // override from a specific implementation.
  onSubmit() {
  }

  displayMessage({ type = MESSAGE_TYPES.SUCCESS, message = null } = {}) {
    const { messageContainer } = this.refs;
    if (messageContainer) {
      if (!message) {
        // clear all
        Object.values(MESSAGE_TYPES).forEach(className => messageContainer.classList.remove(className));
        messageContainer.innerHTML = '';
        return;
      }
      messageContainer.classList.add(type);
      messageContainer.innerHTML = message;
    }
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(this.refs.form);
    await this.onSubmit(formData, e);
  };

  init = () => {
    this.refs.form.addEventListener('submit', this._handleSubmit);
  };
}

import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class SolutionFeaturesGrid extends BaseComponent {
    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el.querySelectorAll('.SolutionFeaturesGrid__feature'),
            onInViewport: (target) => {
                const targetTl = gsap.timeline();

                targetTl.from(target, {
                    duration: 0.6,
                    opacity: 0,
                    x: 30,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                tl.add(targetTl, tl.isActive() ? '<0.13' : '+=0')// stagger 0.13s with independent reveal animations
            }
        }];
    }
}

RuntimeComponentFactory.add('SolutionFeaturesGrid', SolutionFeaturesGrid);

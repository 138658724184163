class RuntimeComponentFactory {
  constructor() {
    this._ = {};
  }

  getDictionary = () => this._;

  /**
   add a class to the factory to be instantiated later with using the same typeID
   */
  add = (typeID, classRef) => {
    this._[typeID] = classRef;
  };

  /**
   get a class with the given typeID.
   */
  get = (typeID) => {
    return this._[typeID];
  };
}

const instance = new RuntimeComponentFactory();
export default instance;

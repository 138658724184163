export function dispatch(target, evtName, data) {
  const event = new CustomEvent(evtName, { detail: data });
  target.dispatchEvent(event);
}

const Events = {
  AJAX_UPDATE: 'ajaxUpdate',
  TRIGGER_LOAD_LAZY: 'triggerLoadLazy',
  HEADER_MOUSELEAVE: 'headerMouseLeave'
};

export default Events;

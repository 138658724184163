import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';
import Events from '../utilities/customEvents.js';

const TAB_ACTIVE_CLASS = 'active';
const TOGGLER_ACTIVE_CLASS = 'active';
const TOGGLER_OPEN_CLASS = 'has-open';

function bindEscKey(el, fn) {
    const handler = (e) => {
        e.stopPropagation();
        if (e.key === 'Esc' || e.key === 'Escape') {
            fn(e);
        }
    };
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler); // return function to destroy listener
}

class MegaMenu extends BaseComponent {
    requiredRefs = ['redboxContainer'];

    constructor(el, componentName) {
        super(el, componentName);

        this.currentActive = null;
        this.dom = {
            tabs: this.el.querySelectorAll('.MegaMenu__tab'),
            togglers: document.querySelectorAll('[ data-megamenu-toggle]'),
        };

        // Options for the observer (which mutations to observe)
        const config = {attributes: true};
        // Create an observer instance linked to the callback function
        this.observer = new MutationObserver((mutationsList, observer) => {
            // Use traditional 'for loops' for IE 11
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    const {target} = mutation;
                    const isActive = target.classList.contains(TAB_ACTIVE_CLASS);
                    this.handleChange(target, isActive);
                }
            }
        });

        // Start observing the target nodes for configured mutations
        this.dom.tabs.forEach((el) => this.observer.observe(el, config));
        this.dom.togglers.forEach((el) => {
            el.addEventListener('mouseenter', this.handleTogglerEnter);
            el.addEventListener('mouseleave', this.handleTogglerLeave);
        });

        document.body.addEventListener(Events.HEADER_MOUSELEAVE, this.handleHeaderMouseLeave);
    }

    getAssociatedTogglerOfTab = (tabEl) => {
        return [...this.dom.togglers].find((toggler) => tabEl.dataset.tabId === toggler.getAttribute('id'));
    }

    handleHeaderMouseLeave = () => {
        if (this.currentActive) this.handleChange();
    };

    handleTogglerEnter = (e) => {
        e.preventDefault();
        const {target} = e;
        const tabId = target.getAttribute('id');
        if (tabId) {
            // A toggler which has a dropdown menu
            target.classList.add(TOGGLER_ACTIVE_CLASS);
            const targetTab = [...this.dom.tabs].find((tab) => tab.dataset.tabId === tabId);
            if (targetTab && targetTab !== this.currentActive) targetTab.classList.add(TAB_ACTIVE_CLASS);
        } else {
            // Toggler without a submenu
            this.handleChange();
        }
    };

    handleTogglerLeave = (e) => {
        e.preventDefault();
        const {target} = e;
        const tabId = target.getAttribute('id');
        if (tabId) {
            target.classList.remove(TOGGLER_ACTIVE_CLASS);
            // const targetTab = [...this.dom.tabs].find((tab) => tab.dataset.tabId === tabId);
            // if (targetTab) targetTab.classList.remove(TAB_ACTIVE_CLASS);
        }
    };

    handleChange = async (target = null, isActive) => {
        if (target === null) {
            // close the megamenu
            this.handleHeightChange(0);
            if (this.currentActive) {
                this.animateTabOut(this.currentActive);
                const targetToggler = this.getAssociatedTogglerOfTab(this.currentActive);
                if (targetToggler) targetToggler.classList.remove(TOGGLER_OPEN_CLASS)
            }
            this.currentActive = null;
            return;
        }

        if (isActive) {
            let newHeight = target.offsetHeight;
            if (this.currentActive) {
                await this.animateTabOut(this.currentActive);
                const targetToggler = this.getAssociatedTogglerOfTab(this.currentActive);
                if (targetToggler) targetToggler.classList.remove(TOGGLER_OPEN_CLASS)
            }


            this.currentActive = target;
            this.handleHeightChange(newHeight);
            this.animateTabIn(target);
            const targetToggler = this.getAssociatedTogglerOfTab(target);
            if (targetToggler) targetToggler.classList.add(TOGGLER_OPEN_CLASS)
        }
    };

    animateTabOut = (tabEl) => {
        const _this = this;
        return gsap.to(tabEl, {autoAlpha: 0}).then(() => {
            if (_this.currentActive !== tabEl) tabEl.classList.remove(TAB_ACTIVE_CLASS);
        });
    };

    animateTabIn = (tabEl) => {
        return gsap.to(tabEl, {
            autoAlpha: 1,
            duration: 0.3,
            ease: 'power2.easeIn'
        });
    };

    handleHeightChange = (height) => {
        return gsap.to(this.el, {height});
    };
}

RuntimeComponentFactory.add('MegaMenu', MegaMenu);


import RuntimeComponentFactory from './factory/RuntimeComponentFactory';

import './plugins/lazyLoad.js'
import './plugins/scrollToAnchor.js'
import './plugins/serviceWorkers'

/*
Components
 */
import './components/Lightbox.js';
import './components/Slider.js';

/*
Partials
 */
import './partials/Header.js';
import './partials/MegaMenu.js';
import './partials/PushMenu.js';


/*
Modules
 */
import './modules/AboutHero';
import './modules/AboutServices';
import './modules/AboutTimeline';
import './modules/CareerContactForm';
import './modules/CareerOpenPositionsList';
import './modules/CommonBreadcrumbs'
import './modules/CommonCaption';
import './modules/CommonHeading';
import './modules/CommonHeadline';
import './modules/CommonLightContactForm';
import './modules/CommonHeavyContactForm';
import './modules/ContactEmployees';
import './modules/ContactHero';
import './modules/ContactMap';
import './modules/ContactOffice';
import './modules/HomepageHero';
import './modules/HomepagePortfolio';
import './modules/HomepageReferences';
import './modules/HomepageServices';
import './modules/HomepageTestimonial'
import './modules/ProductCards';
import './modules/ProductCategoryHero';
import './modules/ProductCertificates';
import './modules/ProductOptions';
import './modules/ProductHero';
import './modules/ProductFeatures';
import './modules/ProductDownloads';
import './modules/ProductGallery';
import './modules/ProductRichFeatures';
import './modules/ProductTechnicalInformation';
import './modules/ProductCategoryProductsList';
import './modules/ReferencesList';
import './modules/ReferencesProjectCategories';
import './modules/SolutionHero';
import './modules/SolutionFeaturesCards';
import './modules/SolutionFeaturesGrid';
import './modules/SolutionFeaturesList';
import './modules/SolutionProductCategories';
import './modules/SolutionProduct';
import './modules/SolutionProducts';


/*
* Initialize registered components
*/
const components = [];
Object.entries(RuntimeComponentFactory.getDictionary()).map(([componentName, componentClassDefinition]) => {
    document.querySelectorAll(`.${componentName}`).forEach((el) => {
        components.push(new componentClassDefinition(el, componentName));
    });
});

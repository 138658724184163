import { dashToCamel } from '../utilities/convertCase';
import { once } from '../utilities/functions.js';

export class BaseComponent {
  constructor(el, componentName) {
    this.el = el;
    this.componentName = componentName;
    this.refs = {};
    this.requiredRefs = [];
    this.baseInit();
  }

  baseInit() {
    if (process.env.NODE_ENV === 'development') {
      this.el.$components = [...(this.el.$components || []), this];
    }
    this.populateRefsObject();
    this.checkRequiredRefs();


    // module animations
    const revealAnimations = this.revealAnimations; // in child class through property getter (get revealAnimations() {})
    if (revealAnimations && revealAnimations.length) {
      const doOnInViewport = new Map();

      revealAnimations.forEach(({ targets, onInViewport }) => {
        const targetsArr = [].concat(targets.length ? [...targets] : targets); // can be multiple, convert all to array
        targetsArr.forEach((el) => {
          doOnInViewport.set(el, once((triggeredEl) => {
            if (onInViewport) onInViewport(triggeredEl);
          }));
        });
      });


      this._observer = new IntersectionObserver((targets) => {
        targets.forEach((triggeredEntry) => {
          if (triggeredEntry.isIntersecting) { // if coming in, not out
            const { target } = triggeredEntry;
            target.classList.add('is-seen');
            doOnInViewport.get(target)(target);
            this._observer.unobserve(target);
          }
        });
      }, {
        threshold: 0.15
      });

      doOnInViewport.forEach((callback, el) => this._observer.observe(el));
    }
  }

  populateRefsObject() {
    const attribute = `data-ref-${this.componentName}`;

    this.el.querySelectorAll(`[${attribute}]`).forEach((refElement) => {
      const attributeValue = dashToCamel(refElement.getAttribute(attribute));
      if (!this.refs[attributeValue]) { // does not exist yet
        this.refs[attributeValue] = refElement;
      } else if (!this.refs[attributeValue].length) { // has first value but is not array yet
        this.refs[attributeValue] = [this.refs[attributeValue], refElement];
      } else { // has multiple values in an array already
        this.refs[attributeValue].push(refElement);
      }
    });
  }

  checkRequiredRefs = () => {
    if (this.requiredRefs && this.requiredRefs.length) {
      this.requiredRefs.forEach((key) => {
        if (!(key in this.refs)) throw new ReferenceError(`Reference ${key} in component ${this.componentName} could not be found.`);
      });
    }
  };

  cleanup() {
    // Override this function in your classes as required
  }
}

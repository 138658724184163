import {BaseComponent} from './_BaseComponent.js';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeUi from 'photoswipe/dist/photoswipe-ui-default.min';

export default class Lightbox extends BaseComponent {
    requiredRefs = ['container'];
    lightbox = null;
    template = `
        <!-- Root element of PhotoSwipe. Must have class pswp. -->
        <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

            <!-- Background of PhotoSwipe.
                It's a separate element as animating opacity is faster than rgba(). -->
            <div class="pswp__bg"></div>

                <!-- Slides wrapper with overflow:hidden. -->
                <div class="pswp__scroll-wrap">

                    <!-- Container that holds slides.
                        PhotoSwipe keeps only 3 of them in the DOM to save memory.
                        Don't modify these 3 pswp__item elements, data is added later on. -->
                    <div class="pswp__container">
                        <div class="pswp__item"></div>
                        <div class="pswp__item"></div>
                        <div class="pswp__item"></div>
                    </div>

                    <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
                    <div class="pswp__ui pswp__ui--hidden">

                    <div class="pswp__top-bar">

                    <!--  Controls are self-explanatory. Order can be changed. -->

                    <div class="pswp__counter"></div>

                        <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

                        <button class="pswp__button pswp__button--share" title="Share"></button>

                        <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

                        <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                        <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                        <!-- element will get class pswp__preloader--active when preloader is running -->
                        <div class="pswp__preloader">
                            <div class="pswp__preloader__icn">
                                <div class="pswp__preloader__cut">
                                    <div class="pswp__preloader__donut"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                        <div class="pswp__share-tooltip"></div>
                    </div>

                    <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button>

                    <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>

                    <div class="pswp__caption">
                        <div class="pswp__caption__center"></div>
                    </div>

                </div>

            </div>

        </div>
    `;

    constructor(el, componentName) {
        super(el, componentName);
        this.init();
    }

    prepareImages = () => {
        let images = [];

        if (!this.refs.image.length) {
            this.refs.image = [this.refs.image];
        }

        this.refs.image.forEach((image) => {
            images.push({
                src: image.dataset.url,
                w: image.dataset.width,
                h: image.dataset.height
            })
        });

        return images;
    }

    openGallery = (e) => {
        e.preventDefault();

        // set options while using the current image index
        const options = {
            bgOpacity: 0.7,
            index: e.currentTarget.dataset.index - 1,
            shareEl: false,
            fullscreenEl: false,
            closeOnScroll: false,
        };

        // crete items array in the required format based on the HTML markup
        const images = this.prepareImages();

        const gallery = new PhotoSwipe(this.el.querySelector('.pswp'), PhotoSwipeUi, images, options);
        gallery.init();
    }

    init = () => {
        // insert html skeleton in template
        this.el.insertAdjacentHTML('beforeend',this.template)

        // bind opening gallery
        this.el.querySelectorAll('.Image').forEach((el) => {
            el.addEventListener("click", this.openGallery);
        });
    }
}

RuntimeComponentFactory.add('Lightbox', Lightbox);

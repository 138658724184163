import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class AboutHero extends BaseComponent {
    get revealAnimations() {
        return [{
            targets: this.el,
            onInViewport: () => {
                const contentTargets = this.el.querySelector('.AboutHero__content').children;
                gsap.from(contentTargets, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const imageTarget = this.el.querySelector('.AboutHero__image');
                gsap.from(imageTarget, {
                    duration: 0.6,
                    opacity: 0,
                    x: 60,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('AboutHero', AboutHero);

import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class ProductDownloads extends BaseComponent {
    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el,
            onInViewport: (target) => {
                tl.from(target.querySelector('.ProductDownloads__heading'), {
                    duration: 0.6,
                    opacity: 0,
                    x: 30,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                tl.from(target.querySelectorAll('.ProductDownloads__file-wrapper'), {
                    duration: 0.6,
                    opacity: 0,
                    x: 30,
                    stagger: 0.13,
                    ease: 'power2.easeOut'
                }, tl.isActive() ? '<0.13' : '+=0') // stagger 0.13s with independent reveal animations);
            }
        }];
    }
}

RuntimeComponentFactory.add('ProductDownloads', ProductDownloads);

import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class CommonHeading extends BaseComponent {
    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el,
            onInViewport: (target) => {
                gsap.from(target.querySelectorAll('.CommonHeading__heading'), {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('CommonHeading', CommonHeading);

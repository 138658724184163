import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class ProductCategoryHero extends BaseComponent {
    get revealAnimations() {
        const tl = gsap.timeline();
        return [{
            targets: this.el,
            onInViewport: (target) => {
                const contentTargets = target.querySelector('.ProductCategoryHero__content-wrapper').children;
                gsap.from(contentTargets, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const imageTarget = target.querySelector('.ProductCategoryHero__image');
                gsap.from(imageTarget, {
                    duration: 0.6,
                    opacity: 0,
                    x: 60,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                tl.from(target.querySelectorAll('.ProductCategoryHero__Children .Child'), {
                    duration: 0.6,
                    opacity: 0,
                    x: 30,
                    stagger: 0.13,
                    ease: 'power2.easeOut'
                }, tl.isActive() ? '<0.13' : '+=0') // stagger 0.13s with independent reveal animations);
            }
        }];
    }
}

RuntimeComponentFactory.add('ProductCategoryHero', ProductCategoryHero);

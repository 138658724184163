import gsap from 'gsap';
import balanceText from 'balance-text';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class CommonHeadline extends BaseComponent {
    constructor(el, componentName) {
        super(el, componentName);
        balanceText();
    }

    get revealAnimations() {
        return [{
            targets: this.el,
            onInViewport: (target) => {

                let targets = [];

                if (target.querySelector('.Headline__tagline')) {
                    targets.push(target.querySelector('.Headline__tagline'));
                }

                if (target.querySelector('.Headline__heading')) {
                    targets.push(target.querySelector('.Headline__heading'));
                }

                if (target.querySelector('.Headline__description')) {
                    targets.push(target.querySelector('.Headline__description'));
                }

                gsap.from(targets, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                })
            }
        }];
    }
}

RuntimeComponentFactory.add('Headline', CommonHeadline);

import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import Form, {MESSAGE_TYPES} from '../components/Form.js';
import {api} from '../api.js';

const MAKE_ENDPOINT = 'https://hook.eu1.make.com/m1xyuwnw3i1n7jn66yuq3qnncaskaqif';

class CommonHeavyContactForm extends Form {
    async onSubmit(formData) {

        // compose e-mail message
        const fromName = formData.get('fromName');
        const fromEmailAddress = formData.get('fromEmail');
        const fromPhone = formData.get('fromPhone');
        const fromCompany = formData.get('fromCompany');
        const message = formData.get('message');


        const messageSubject = `🚨 Nová poptávka z webových stránek www.fkservis.cz (${fromName}, ${fromCompany})`;
        const messageBody = `
            Dobrý den, <br/> <br/>
            pan/í <b>${fromName}</b> z firmy <b>${fromCompany}</b> nám zasílá následující dotaz: <br/><br/> 
            „<i>${message}</i>” <br/><br/> 
            Prosím, kontaktujte co nejdříve zákazníka na e-mailové adrese: <b>${fromEmailAddress}</b> nebo telefonním čísle: <b>${fromPhone}</b>.
        `;


        try {
            const {data} = await api(MAKE_ENDPOINT, {
                method: 'POST',
                body: JSON.stringify({messageSubject, messageBody})
            })

            console.log('Data received: ', data);
            this.refs.form.reset();
            this.displayMessage({message: 'Děkujeme, vaše zpráva k nám úspěšně dorazila. V nejbližším možném termínu vám odpovíme.\n'});
        } catch (errResponse) {
            console.error(errResponse);
            this.displayMessage({type: MESSAGE_TYPES.ERROR, message: 'Při odesílání formuláře se stala chyba'});
            // handle error
            // const {status, data, message} = errResponse;
        }
    }
}

RuntimeComponentFactory.add('CommonHeavyContactForm', CommonHeavyContactForm);

import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class HomepageHero extends BaseComponent {
    constructor(el, componentName) {
        super(el, componentName);

        this.slider = this.refs.sliderEl && this.refs.sliderEl.slider;

        this.init();
    }

    init = () => {
        if (this.slider) {
            this.slider.on('slideChange', this.onSlideChange);
        }
    };

    onSlideChange = () => {
        if (this.refs.asideContent) {
            const slideFigcaption = this.slider.getCurrentSlide().querySelector('figcaption');
            this.refs.asideContent.innerHTML = slideFigcaption ? slideFigcaption.innerHTML : '';
        }
    };

    get revealAnimations() {
        return [{
            targets: this.el,
            onInViewport: () => {
                const contentTargets = this.el.querySelector('.HomepageHero__content').children;
                gsap.from(contentTargets, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const sliderTarget = this.el.querySelector('.HomepageHero__slider');
                gsap.from(sliderTarget, {
                    duration: 0.6,
                    opacity: 0,
                    x: 60,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('HomepageHero', HomepageHero);

import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';
import gsap from "gsap";

class HomepageReferences extends BaseComponent {
    constructor(el, componentName) {
        super(el, componentName);

        this.slider = this.refs.sliderEl && this.refs.sliderEl.slider;

        this.init();
    }

    init = () => {
        if (this.slider) {
            this.slider.on('slideChange', this.onSlideChange);
        }
    };

    onSlideChange = () => {
        if (this.refs.asideContent) {
            const slideFigcaption = this.slider.getCurrentSlide().querySelector('figcaption');
            this.refs.asideContent.innerHTML = slideFigcaption ? slideFigcaption.innerHTML : '';

            const contentTargets = this.refs.asideContent.children;
            gsap.from(contentTargets, {
                duration: 0.6,
                opacity: 0,
                y: 30,
                stagger: 0.13,
                transition: 'none',
                clearProps: 'all',
                ease: 'power2.easeOut'
            });
        }
    };

    get revealAnimations() {
        return [{
            targets: this.el,
            onInViewport: () => {
                const contentTargets = this.el.querySelector('.References__content').children;
                gsap.from(contentTargets, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const imageTarget = this.el.querySelector('.References__slider');
                gsap.from(imageTarget, {
                    duration: 0.6,
                    opacity: 0,
                    y: 90,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const bgTarget = this.el.querySelector('.References__bg');
                gsap.from(bgTarget, {
                    duration: 0.6,
                    opacity: 0,
                    y: 60,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });
            }
        }]
    };
}

RuntimeComponentFactory.add('References', HomepageReferences);

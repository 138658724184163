import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class CommonCaption extends BaseComponent {
    get revealAnimations() {
        return [{
            targets: this.el,
            onInViewport: () => {
                const contentTargets = this.el.querySelector('.CaptionBox__content-wrapper').children;
                gsap.from(contentTargets, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                const bgTarget = this.el.querySelector('.CaptionBox__content-wrapper');
                gsap.from(bgTarget, {
                    duration: 1.2,
                    borderWidth: 0,
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('CaptionBox', CommonCaption);

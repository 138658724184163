const DEFAULT_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export async function api(endpoint, options) {
    const _options = {
        // although this is the default behavior according to the spec
        // firefox does not send the cookies without this explicit flag
        credentials: 'same-origin',
        ...options,
        headers: {
            ...DEFAULT_HEADERS,
            ...options.headers,
        },
    };

    const res = await fetch(`${endpoint}`, _options);

    // handle json responses
    if (res.status === 204) { // HTTP no content
        res.data = null;
    } else if (((_options.headers || {}).Accept === 'application/json') && (res.headers.get('Content-Type') === 'application/json')) {
        res.data = await res.json();
    }

    // Ok request
    if (!res.ok) {
        throw res;
    }

    return res;
}

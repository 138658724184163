import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import {BaseComponent} from '../components/_BaseComponent.js';

class SolutionProducts extends BaseComponent {
    get revealAnimations() {
        return [{
            targets: this.el,
            onInViewport: (target) => {
                const elements = {
                    commonFeatures: target.querySelectorAll('.SolutionProducts__common-features__feature-wrapper'),
                    products: target.querySelectorAll('.SolutionProducts__products__product')
                }

                gsap.from(elements.commonFeatures, {
                    duration: 0.6,
                    opacity: 0,
                    x: 30,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                gsap.from(elements.products, {
                    duration: 0.6,
                    opacity: 0,
                    x: 60,
                    stagger: 0.13,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });

                gsap.from(elements.button, {
                    duration: 0.6,
                    opacity: 0,
                    y: 30,
                    transition: 'none',
                    clearProps: 'all',
                    ease: 'power2.easeOut'
                });
            }
        }];
    }
}

RuntimeComponentFactory.add('SolutionProducts', SolutionProducts);

import gsap from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory.js';
import { BaseComponent } from '../components/_BaseComponent.js';

class HomepagePortfolio extends BaseComponent {
  requiredRefs = ['productLink'];
  timer = null;
  isImageChanged = false;

  constructor(el, componentName) {
    super(el, componentName);
    this.init();
  }

  init = () => {
    this.refs.productLink.forEach(el => {
      el.addEventListener('mouseenter', this.handleMouseEnter(el.dataset.product));
      el.addEventListener('mouseleave', this.handleMouseLeave(el.dataset.product));
    });

    this.dom = {
      defaultImage: this.el.querySelector('.Portfolio__card--multiple figure[data-product="default"]'),
      allImages: this.el.querySelectorAll('.Portfolio__card--multiple figure'),
    };

    // prevents all images appearing on first hover animation
    gsap.set(this.dom.allImages, {
      autoAlpha: 0
    });
    gsap.set(this.dom.defaultImage, {
      autoAlpha: 1
    });
  };

  handleMouseEnter = (productName) => e => {
    const image = this.el.querySelector(`.Portfolio__card--multiple figure[data-product="${productName}"]`);
    if (image) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isImageChanged = true;
        this.animateToImage(image);
      }, 100); // timeout to prevent changing when just movning mouse through the module
    }
  };

  handleMouseLeave = (productName) => e => {
    clearTimeout(this.timer);
    if (this.isImageChanged) {
      // timeout to prevent animating to default when hovering from category to category
      this.timer = setTimeout(this.revertToDefault, 500);
    }
  };

  revertToDefault = () => {
    this.animateToImage(this.dom.defaultImage);
  };

  animateToImage = (figureEl) => {
    const tl = gsap.timeline();
    tl.to(this.dom.allImages, {
      duration: 0.25,
      autoAlpha: 0
    });
    tl.to(figureEl, {
      duration: 0.35,
      autoAlpha: 1
    }, '-=0.1');
  };

  get revealAnimations() {
    return [{
      targets: this.el,
      onInViewport: () => {
        const rightTarget = this.el.querySelectorAll('.Portfolio__card:first-child');
        gsap.from(rightTarget, {
          duration: 0.6,
          opacity: 0,
          x: -30,
          stagger: 0.13,
          transition: 'none',
          clearProps: 'all',
          ease: 'power2.easeOut'
        });

        const leftTarget = this.el.querySelectorAll('.Portfolio__card:last-child');
        gsap.from(leftTarget, {
          duration: 0.6,
          opacity: 0,
          x: 30,
          stagger: 0.13,
          transition: 'none',
          clearProps: 'all',
          ease: 'power2.easeOut'
        });
      }
    }];
  }
}

RuntimeComponentFactory.add('Portfolio', HomepagePortfolio);
